import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ModelList from "../components/model-list/model-list"
import { Flex, Text, Card, Image, Heading } from "rebass"
import { Container} from '../components/shared/container';


function BrandTemplate(props) {
  const data = props.data.contentfulBrand
  return (
    <Layout>
      <Container>
        <div>
          <h1> {data.name} Philippines 2019</h1>
        </div>
        <p>{data.description ? data.description.description : ""}</p>

        {data.models ? (
          <ModelList brandName={data.slug} models={data.models}></ModelList>
        ) : (
            ""
          )}
      </Container>
    </Layout>
  )
}
export default BrandTemplate
export const pageQuery = graphql`
  query($id: String) {
    contentfulBrand(id: { eq: $id }) {
      name
      slug
      models {
        name
        slug
        mainImage
      }
      description {
        description
      }
    }
  }
`
