import React from 'react'
import { Box } from 'rebass'
export const Container = props =>
    <Box
        {...props}
        sx={{
            maxWidth: '1024px',
            mx: 'auto',
            px: 3,
        }}
    />
