import React from "react"
import ModelCard from "../model-card/model-card"
import { Flex } from "rebass"

const ModelList = ({ models, brandName }) => {
  return (
    <Flex
    flexWrap="wrap"
    flex="auto 0"
    justifyContent="space-around"
    >
      {models.map(model => (
        <ModelCard
          brandName={brandName}
          key={model.name}
          model={model}
        ></ModelCard>
      ))}
    </Flex>
  )
}

export default ModelList
