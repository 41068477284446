import React from "react"
import Link from "../shared/link"
import { Image, Heading, Card, Text, Button } from "rebass"

const ModelCard = ({ model, brandName }) => {
  const modelName = model.slug
  return (
    <Card width={["100% ", "30%"]} m="2" my="4">
      <Link to={`/brands/${brandName}/${modelName}`}>
        <Image src={model.mainImage} />
        <Heading textAlign="center" as="h3">
          {model.name}
        </Heading>
        {/* <Text textAlign="center">Price Range</Text> */}
        <Text p="1" >Starts at PHP 900,000</Text>
        <Button sx={{cursor:'pointer'}} m="auto" display="block"> <b>Learn More </b></Button>
      </Link>
    </Card>
  )
}

export default ModelCard
